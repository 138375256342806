<template>
    <div class="community oepnX" v-if="tagInfo">
        
        <div class="headBar">
            <div class="leftIcon" @click="$router.go(-1)">
                <img class="navArrow" src="@/assets/png/leftH.png" />
            </div>
<!--            <div class="headTitle">-->
<!--                <span>{{ tagInfo.name }}</span>-->
<!--            </div>-->
            <div class="focusBtn" v-if="!tagInfo.hasCollected" @click="focus(tagInfo)">
<!--                <svg-icon icon-class="btnAddWhite" class="btnIcon"></svg-icon>-->
              <svg-icon icon-class="btnAdd" class="btnIcon"></svg-icon>
                <span>关注</span>
            </div>
            <div class="alreadyFocus" v-else @click="focus(tagInfo)">
                <span>已关注</span>
            </div>
        </div>
        
        <div class="headBg">
            <ImgDecypt v-if="tagInfo.coverImg" :src="tagInfo.coverImg" />
        </div>
        
        <div class="tagContent">
            <div class="tagInfo">
                <div class="tagName">
                    <span>{{tagInfo.name}}</span>
                </div>

            </div>
            
            <div class="tagDesc">
                <span>
                    {{tagInfo.tagDesc}}
                </span>
            </div>
        </div>
        
        <van-tabs class="tabs" swipeable background="rgba(0,0,0,0)" @change="tabChange" line-width="18" line-height="2" color="#FBD13D" title-inactive-color="#666666" title-active-color="#333333" v-model="tabActive">
            <van-tab title="最新" class="tabContent">
                <PullRefresh v-if="!rIsLoading" :loading="rLoading" :refreshing="rRefreshing" :finished="rFinished" @onLoad="onLoad"
                             @onRefresh="onRefresh" :isNoData="rIsNoData" :error="error" :is-higeht-max="true"
                             :adv-list="advList" :hasAdvPagination="true" className="TopicDetails">
                    <div class="makeContent">
                        <!-- 帖子列表 -->
                        <div class="communityList">
                            <CommunityList :communityList="rCommunityList"></CommunityList>
                        </div>
                    </div>
                </PullRefresh>
                <Loading v-else />
            </van-tab>
            <van-tab title="最热" class="tabContent">
                <PullRefresh v-if="!gIsLoading" :loading="gLoading" :refreshing="gRefreshing" :finished="gFinished" @onLoad="onLoad"
                             @onRefresh="onRefresh" :isNoData="gIsNoData" :error="error" :is-higeht-max="true"
                             :adv-list="advList" :hasAdvPagination="true" className="TopicDetails">
                    <div class="focusContent">
                        <div class="communityList">
                            <CommunityList :communityList="gCommunityList"></CommunityList>
                        </div>
                    </div>
                </PullRefresh>
                <Loading v-else />
            </van-tab>
        </van-tabs >

        <van-overlay :show="releaseShow" @click="releaseShow = false">
            <div class="releaseBtns" @click.stop>
                <div class="releaseBtnGroup">
                    <router-link tag="div" to="/publishImg">
                        <span>图片</span>
                    </router-link>
                    <div class="division"></div>
                    <router-link tag="div" to="/publishVideo">
                        <span>视频</span>
                    </router-link>
                    <!-- <div @click="releasePage">
                        <span>图片</span>
                    </div>
                    <div class="division"></div>
                    <div @click="releasePage">
                        <span>视频</span>
                    </div> -->
                </div>
            </div>
        </van-overlay>
<!--        发布按钮-->
<!--        <div class="publishBtn" @click="showPublishBtn = !showPublishBtn"></div>-->

        <van-overlay class="publishPop" :show="showPublishBtn" @click="showPublishBtn = false">
            <div class="publishImg" @click="jumpImg"></div>
            <div class="publishVideo" @click="jumpVideo"></div>
        </van-overlay>
        <!--      <div class="collectBtn" v-else @click="cancelCollectionWorks">-->
        <!--          <img src="@/assets/png/hasCollectBtn.png">-->
        <!--      </div>-->
    </div>

</template>
<script>

import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import {
    collectTag,
    queryCommunityList,
    queryCommunityTagList,
    queryTagInfo
} from "@/api/community";
import ImgDecypt from "@/components/ImgDecypt";

import CommunityList from "@/components/community/CommunityList";
import {AdType, getAdItem, jumpAdv} from "@/utils/getConfig";
import { Overlay } from 'vant';
import {careOrcancle} from "@/api/user";
import {mapGetters} from "vuex";

export default {
    name: "TopicDetails",
    components:{
        PullRefresh,
        Loading,
        ImgDecypt,
        CommunityList,
        [Overlay.name]: Overlay,
    },
    data(){
        return {
            gIsLoading: true,
            rIsLoading: true,
            gLoading: true,
            rLoading: true,
            gFinished: false,
            rFinished: false,
            gIsNoData:false,
            rIsNoData: false,
            error: false,
            advList: [],
            gPageNumber: 1,
            rPageNumber: 1,
            gPageSize: 12,
            rPageSize: 12,
            gCommunityList: [],
            rCommunityList: [],
            tabActive: 1,
            commentsShow: false,
            gRefreshing: false,
            rRefreshing: false,
            hotspotWonderList:[],
            releaseShow: false,
            bannerList:[],
            tagID: "",
            tagInfo: null,
            showPublishBtn: false,
        }
    },
    computed:{
        ...mapGetters({
            userInfo: 'userInfo',
            isVip: 'isVip'
        }),
    },
    mounted() {
        let query = this.$route.query;
        
        if (query){
            this.tagID = query.id;
            this.getTagInfo();

            if(this.tabActive == 1){
                this.getFollowDynamicsList('refresh');
                // this.getHotspotWonderList();
            }else{
                this.getCommunityList('refresh');
            }
            this.bannerList = getAdItem(AdType.MOVIES_DOMESTIC);
        }
        
    },methods:{
        async getTagInfo() {
            let req = {
                tagID: this.tagID
            }
            let res = await this.$Api(queryTagInfo, req);

            if(res && res.code == 200){

                this.$bus.$emit("dataBuriedEvent",{
                    "dataType":2,
                    tagsName: res.data.name
                });
                
                this.tagInfo = res.data;
            }
        },
        onRefresh() {
            if(this.tabActive == 0){
                this.rRefreshing = true;
                this.rPageNumber = 1;
                this.rFinished = false;
                this.rIsNoData = false;
                this.getCommunityList('refresh');
            }else{
                this.gRefreshing = true;
                this.gPageNumber = 1;
                this.gFinished = false;
                this.gIsNoData = false;
                this.getFollowDynamicsList('refresh');
            }
        },onLoad(){
            if(this.tabActive == 0){
                this.rLoading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.rPageNumber++
                }
                this.getCommunityList();
            }else{
                if (this.error) {
                    this.error = false;
                } else {
                    this.gPageNumber++
                }
                this.gLoading = true;
                this.getFollowDynamicsList();
            }
        },async getCommunityList(type) {
            /**
             * 帖子最新列表
             */
            let req = {
                pageNumber: this.rPageNumber,
                pageSize: this.rPageSize,
                tag: this.tagID,
                sort: 0,
                reqDate: new Date().toISOString()
            }
            let res = await this.$Api(queryCommunityList, req);

            this.rLoading = false;
            this.rRefreshing = false;
            this.rIsLoading = false;

            try {
                if(res && res.code == 200){

                    if(res.data.list){
                        if(type == "refresh"){
                            this.rCommunityList = res.data.list;
                        }else {
                            this.rCommunityList = this.rCommunityList.concat(res.data.list);
                        }
                    }

                    if (this.rPageNumber === 1 && this.rCommunityList.length === 0) {
                        this.rIsNoData = true;
                    }

                    if (!res.data.hasNext || (res.data.list && res.data.list.length < req.rPageSize)) {
                        this.rFinished = true;
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.rLoading = false;
            }
        },async getFollowDynamicsList(type) {
            /**
             * 获取最热列表
             */
            let req = {
                pageNumber: this.gPageNumber,
                pageSize: this.gPageSize,
                tag: this.tagID,
                sort: 1,
                reqDate: new Date().toISOString()
            }
            let res = await this.$Api(queryCommunityTagList, req);

            this.gLoading = false;
            this.gRefreshing = false;
            this.gIsLoading = false;

            try {
                if(res && res.code == 200){

                    if(res.data.list){
                        if(type == "refresh"){
                            this.gCommunityList = res.data.list;
                        }else {
                            this.gCommunityList = this.gCommunityList.concat(res.data.list);
                        }
                    }

                    if (this.gPageNumber === 1 && this.gCommunityList.length === 0) {
                        this.gIsNoData = true;
                    }

                    if(res.data.list){
                        if (!res.data.hasNext || res.data.list.length < req.gPageSize) {
                            this.gFinished = true;
                        }
                    }else{
                        this.gFinished = true;
                    }

                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.gLoading = false;
            }

        },async tabChange(item){
            if(this.tabActive == 0){
                this.rIsLoading = true;
                await this.getCommunityList('refresh');
            }else{
                this.gIsLoading = true;
                await this.getFollowDynamicsList('refresh');
            }
        },async getHotspotWonderList() {
            /**
             * 获取最新列表
             */
            let req = {
                pageNumber: 1,
                pageSize: 4,
            }
            let res = await this.$Api(queryCommunityTagList, req);

            if (res && res.code == 200) {
                this.hotspotWonderList = res.data.list;
            }

        },moreTopic(){
            /**
             * 更多话题（热门话题）
             */
            this.$router.push({
                path:"/hotTopic"
            })
        },releasePage(){
            /**
             * 发布作品
             */

            this.releaseShow = false;

            this.$router.push({
                path:"/releasePage"
            })
        },goAdv(item) {
            jumpAdv(item);
        },async focus(item) {
            /**
             * 关注/取消关注 话题
             */
            let req = {
                isCollect: !item.hasCollected,
                objID: this.tagID,
                type: "tag"
            }
            let res = await this.$Api(collectTag, req);

            if(res && res.code == 200){
                if(!item.hasCollected){
                    item.hasCollected = true;
                    this.$toast({
                        message: '关注成功',
                        position: 'top',
                    });
                }else{
                    item.hasCollected = false;
                    this.$toast({
                        message: '取消关注',
                        position: 'top',
                    });
                }
            }
        },
        jumpImg() {
            if (this.userInfo.isVip) {
                this.$router.push("/publishImg")
            } else {
                this.$bus.$emit("vipPopup", {
                    // id: "",
                    state: 1,
                    // goldenNum: this.postInfo.coins,
                    closeBtn: () => {
                        this.$bus.$emit("closeVipPopup");
                    }
                });
            }
        },
        jumpVideo() {
            if (this.userInfo.isVip) {
                this.$router.push("/publishVideo")
            } else {
                this.$bus.$emit("vipPopup", {
                    // id: "",
                    state: 1,
                    // goldenNum: this.postInfo.coins,
                    closeBtn: () => {
                        this.$bus.$emit("closeVipPopup");
                    }
                });
            }
        },
    },watch:{
        async $route(to, from) {
            if (to.path == "/topicDetails") {
                if (to.query) {
                    if (this.tagID != to.query.id) {
                        this.tagID = to.query.id;
                        await this.getTagInfo();
                        if (this.tabActive == 0) {
                            this.rIsLoading = true;
                            await this.getCommunityList('refresh');
                        } else {
                            this.gIsLoading = true;
                            await this.getFollowDynamicsList('refresh');
                        }
                    }
                }
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.community{
    width: 100%;
    height: 100%;
    /deep/ .van-tabs__nav--line{
        width: 143px;
    }

    /deep/ .van-tabs__wrap{
        //background: rgb(27,22,76);
        //border-bottom: solid 1px rgb(230,230,230);
    }

    /deep/ .van-tabs--line .van-tabs__wrap{
        height: 40px;
    }

    /deep/ .van-tab--active{
        font-size: 14px !important;
        font-weight: bold;
    }

    /deep/ .van-tab{
        font-size: 12px;
    }

    /deep/ .van-overlay{
        // background-color: rgba(0,0,0,0);
        z-index: 10;
    }

    /deep/ .van-tabs__line{
        bottom: 20px
    }
    
    .headBg{
        height: 150px;
        //background-image: linear-gradient(to bottom right, rgba(19,15,50,0), rgb(19,15,50));
        background: rgb(19,15,50);
    }
    
    .tagContent{
        color: rgb(0,0,0);
        padding: 12px 16px;
        
        .tagInfo{
            display: flex;
            
            .tagName{
                font-size: 16px;
                font-weight: bold;
                margin-right: 12px;
                color: #333333;
            }
            
        }
        
        .tagDesc{
            margin-top: 4px;
            font-size: 12px;
            color: #666666;
        }
        
        
    }

    .headBar{
        
        //border: solid 1px red;
        display: flex;
        height: 44px;
        width: calc(100% - 32px);
        padding: 0 16px;
        align-items: center;
        justify-content: space-between;
        //border-bottom: solid 1px rgb(230,230,230);
        position: absolute;
        z-index: 5;

        .leftIcon{
            //padding: 0 12px;
            .navArrow{
                width: 24px;
                height: 24px;
            }
        }

        .headTitle{
            color: rgb(255,255,255);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 24px;
            //font-weight: bold;
            font-size: 18px;
        }


        .btnIcon{
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }

        .focusBtn{
          width: 60px;
          height: 24px;
            font-size: 12px;
            border-radius: 12.5px;
            color: #424242;
            font-weight: 500;
            background: #FBD13D;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .alreadyFocus{
            width: 60px;
            height: 24px;
            font-size: 12px;
            background: #B3B3B3;
            border-radius: 12.5px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .releaseBtns{
        display: flex;
        justify-content: flex-end;
        margin-top: 51px;
        margin-right: 12px;

        .releaseBtnGroup{
            width: 121px;
            height: 41px;
            background: rgb(113,69,231);
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;

            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: rgb(255,255,255);

            .division{
                width: 1px;
                height: 33px;
                background: rgb(255,255,255);
                margin: 0 15px;
            }
        }
    }

    .tabs{
        //height: calc(100vh - 51px - 86px - 120px);
        //border-bottom: solid 1px red;

        .tabContent{
            //height: 100%;
            height: calc(100vh - 51px - 86px - 120px);

            .makeContent{
                //padding: 0 12px;
                //height: calc(100vh - 51px - 86px);

                .advertising{
                    padding: 0 12px;
                    /deep/ .vanImage {
                      border-radius: 8px;
                    }
                    /deep/ .van-image__img {
                      border-radius: 8px;
                    }
                    .swipe{
                        height: 140px;

                        .van-swipe-item{
                            color: #fff;
                            font-size: 20px;
                            line-height: 150px;
                            text-align: center;
                            background-color: rgb(43,39,72);
                        }

                        /deep/ .van-swipe__indicators{
                            left: auto;
                            right: 0;
                            align-items: center;
                        }
                        /deep/ .van-swipe__indicator{
                            width: 6px;
                            height: 6px;
                            background: rgba(255,255,255,.45);
                        }

                        /deep/ .van-swipe__indicator--active{
                            width: 9px;
                            height: 9px;
                        }
                    }
                }

                .hotTopics{
                    border-bottom: solid rgb(230,230,230) 1px;
                    padding: 12px 12px 30px 12px;

                    .topicsHead{
                        color: #000000;
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                        align-items: center;

                        .topicsTitle{
                            font-weight: bold;
                        }

                        .more{
                            font-size: 14px;
                        }

                        img{
                            width: 6.9px;
                            height: 8.6px;
                            margin-left: 6.1px;
                        }
                    }

                    .topicsContent{
                        display: flex;
                        flex-wrap: wrap;

                        .topicsItem{
                            margin-top: 12px;
                            width: 50%;
                            display: flex;
                            align-items: center;
                            //justify-content: space-between;

                            .portrait{
                                width: 42px;
                                height: 42px;
                                border-radius: 21px;
                                background: rgb(230,230,230);
                            }

                            .topicsInfo{
                                margin-left: 6px;
                                font-size: 12px;
                                width: 90px;

                                .topicsInfoTitle{
                                    color: rgb(0,0,0);
                                    font-weight: bold;
                                }

                                .topicsInfoContent{
                                    color: rgb(153,153,153);
                                    overflow: hidden; //超出的文本隐藏
                                    text-overflow: ellipsis; //溢出用省略号显示
                                    white-space: nowrap;  // 默认不换行；
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .editorBtn{
        position: absolute;
        top: 13px;
        right: 12px;
        //right: 12px;
        //padding-top: 12px;
        //background: rgb(27,22,76);

        img{
            width: 18px;
            height: 19px;
        }
    }

    .communityList{
        //margin-top: 30px;


    }
}

.publishBtn {
    position: fixed;
    right: 12px;
    bottom: 120px;
    width: 50px;
    height: 50px;
    background: url("../../assets/png/publishBtn.png") center no-repeat;
    background-size: 100% 100%;
    z-index: 11;
}
.publishPop {
    .publishImg {
        position: absolute;
        right: 12px;
        bottom: 244px;
        width: 50px;
        height: 50px;
        background: url("../../assets/png/publishImg.png") center no-repeat;
        background-size: 100% 100%;
    }
    .publishVideo {
        position: absolute;
        right: 12px;
        bottom: 182px;
        width: 50px;
        height: 50px;
        background: url("../../assets/png/publishVideo.png") center no-repeat;
        background-size: 100% 100%;
    }
}
</style>
